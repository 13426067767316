import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["eqradio", "seradio", "drate", "prate"];

    selectPaymentType(){
        const select_daily = this.drateTarget;
        const select_project = this.prateTarget;
        var parent_1 = document.getElementById("parent-container");
        var parent_2 = document.getElementById("rate-container");
        parent_1.setAttribute('class', 'mb-3 row align-items-center');

        if (select_daily.checked){
            this.displayDailyRate(parent_2);
        }
        if (select_project.checked){
            this.displayProjectRate(parent_2);
        }

    }

    displayDailyRate(parent){
        var project_rate_field = document.getElementById("project-rate");
        if (project_rate_field){
            project_rate_field.remove();
            var label_text_old = document.getElementById("project-rate-label");
            label_text_old.remove();
        }

        var redundant_field = document.getElementById("daily-rate");
        if (redundant_field){
            redundant_field.remove();
            var label_text_redundant = document.getElementById("daily-rate-label");
            label_text_redundant.remove();
        }

        var daily_rate_label = document.createElement("label");
        daily_rate_label.setAttribute('class', 'form-label');
        daily_rate_label.setAttribute('id', 'daily-rate-label');
        parent.appendChild(daily_rate_label);
        var label_text = document.createTextNode("Daily Rate:");
        daily_rate_label.appendChild(label_text);
        
        var daily_rate_field = document.createElement("input");
        daily_rate_field.setAttribute('type', 'text');
        daily_rate_field.setAttribute('name', 'listing[daily_rate]');
        daily_rate_field.setAttribute('class', 'form-control');
        daily_rate_field.setAttribute('placeholder', '$0.00');
        daily_rate_field.setAttribute('id', 'daily-rate');
        parent.appendChild(daily_rate_field);
    }

    displayProjectRate(parent){
        var daily_rate_field = document.getElementById("daily-rate");
        if (daily_rate_field){
            daily_rate_field.remove();
            var label_text_old = document.getElementById("daily-rate-label");
            label_text_old.remove();
        }

        var redundant_field = document.getElementById("project-rate");
        if (redundant_field){
            redundant_field.remove();
            var label_text_redundant = document.getElementById("project-rate-label");
            label_text_redundant.remove();
        }

        var project_rate_label = document.createElement("label");
        project_rate_label.setAttribute('class', 'form-label');
        project_rate_label.setAttribute('id', 'project-rate-label');
        parent.appendChild(project_rate_label);
        var label_text = document.createTextNode("Project Rate:");
        project_rate_label.appendChild(label_text);

        var project_rate_field = document.createElement("input");
        project_rate_field.setAttribute('type', 'text');
        project_rate_field.setAttribute('name', 'listing[project_rate]');
        project_rate_field.setAttribute('class', 'form-control');
        project_rate_field.setAttribute('placeholder', '$0.00');
        project_rate_field.setAttribute('id', 'project-rate');
        parent.appendChild(project_rate_field);
    }

    
    deSelectEquipment(){
        var equipmentbtns = this.eqradioTargets;

        for (var i = 0; i < equipmentbtns.length; i++){
            if (equipmentbtns[i].checked === true){
                equipmentbtns[i].checked = false;
            }
        }
    }

    deSelectService(){
        var servicebtns = this.seradioTargets;

        for (var i = 0; i < servicebtns.length; i++){
            if (servicebtns[i].checked === true){
                servicebtns[i].checked = false;
            }
            
        }

    }

}