import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['form'];

    initialize() {

    }

    connect() {
        mapboxgl.accessToken = process.env.MAP_BOX_API_KEY;
        const geocoderStreet = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            countries: 'us',
            types: 'address'
        });

        // Only add the geocoder search bar if it does not already exist (browser back button otherwise causes duplicates)
        if (!$('.mapboxgl-ctrl-geocoder--input').length) {
            geocoderStreet.addTo('.address-field');
        }

        // Populate the address field with the current address of the user
        const placeholder_address = "660 SW 26th St, Corvallis, OR 97331"

        // Get current user's address to use as placeholder if there is one
        var address = ''
        if (this.data.get('address') != "")
            address = this.data.get('address');

        // Set the address field placeholder
        if (address != "")
            $('.mapboxgl-ctrl-geocoder--input').attr("placeholder", address).val(address)
        else
            $('.mapboxgl-ctrl-geocoder--input').attr("placeholder", placeholder_address)

        // Change styling of search input to match other form inputs (including removing search icon)
        $('.mapboxgl-ctrl-geocoder--input').addClass("form-control")
                                            .addClass("mapbox-input")
                                            .removeClass("mapboxgl-ctrl-geocoder--input")
        $('.mapboxgl-ctrl-geocoder--icon').remove();
    }

    change = () => {
        // Populate address field with the value from the mapbox input before submitting
        // because the mapbox input is separate from the address field used when processing
        // the form submission
        $('#input-address').val($('.mapbox-input').val())
        $(this.formTarget)
          .find('button[type="submit"]')
          .trigger("click");
    }
}