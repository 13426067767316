document.addEventListener("turbolinks:load", function() {

    const input = document.querySelector('#equipment-image-form');

    if (input != null){
        // Listen for files selection
        input.onchange = function() {
            // Retrieve all files
            const files = input.files;

            // Check files count
            if (files.length > 5) {
                alert("A maximum of 5 images is permitted.");
            }
        }
    }
});