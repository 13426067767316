import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["top"]

    goToTop(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

}