import { Controller } from "@hotwired/stimulus"
import { useWindowResize } from "stimulus-use";

export default class extends Controller {
    static targets = ['form', 'locationInput'];
    static equipmentFilters;

    connect(){
      useWindowResize(this)
    }

    change = () => {
      $('#search-address').val($('.mapboxgl-ctrl-geocoder--input').val())
      $(this.formTarget)
        .find('button[type="submit"]')
        .click();
    };

    clear = (e) => {
      e.preventDefault();
      $("input:checkbox").prop('checked', $(this).prop("checked"));
      $(this.formTarget)
        .find('input[type=checkbox]')
        .each(function() {
          this.checked = false;
        });
      $(this.formTarget)
        .find('input[type=text], input[type=search], input[type=date], input[name^=by_location]')
        .val('');
      this.change();
    };

    expandEquipmentFilters = (e) => {
      //var equipment = JSON.parse(e.currentTarget.dataset.searchEquipment);
      var button = $('.equipment-filters').next().get(0);

      if (button.innerHTML == 'View all'){
        for (var i = 0; i < $('.equipment-filters').children().length; i++){
          if ($('.equipment-filters').children()[i].classList.contains('d-none')){
            $('.equipment-filters').children()[i].classList.remove('d-none');
          }
        }
        button.innerHTML = 'View less';
      } else if (button.innerHTML == 'View less') {
        for (var i = 20; i < $('.equipment-filters').children().length; i++){
          if (!$('.equipment-filters').children()[i].classList.contains('d-none')){
            $('.equipment-filters').children()[i].classList.add('d-none');
          }
        }
        button.innerHTML = 'View all';
      }
    }

    expandServiceFilters = (e) => {
      var button = $('.service-filters').next().get(0);

      if (button.innerHTML == 'View all'){
        for (var i = 0; i < $('.service-filters').children().length; i++){
          if ($('.service-filters').children()[i].classList.contains('d-none')){
            $('.service-filters').children()[i].classList.remove('d-none');
          }
        }
        button.innerHTML = 'View less';
      } else if (button.innerHTML == 'View less') {
        for (var i = 20; i < $('.service-filters').children().length; i++){
          if (!$('.service-filters').children()[i].classList.contains('d-none')){
            $('.service-filters').children()[i].classList.add('d-none');
          }
        }
        button.innerHTML = 'View all';
      }
    }

    expandEquipmentFiltersCompressed = (e) => {
      //var equipment = JSON.parse(e.currentTarget.dataset.searchEquipment);
      var button = $('.equipment-filters-compressed').next().get(0);

      if (button.innerHTML == 'View all'){
        for (var i = 0; i < $('.equipment-filters-compressed').children().length; i++){
          if ($('.equipment-filters-compressed').children()[i].classList.contains('d-none')){
            $('.equipment-filters-compressed').children()[i].classList.remove('d-none');
          }
        }
        button.innerHTML = 'View less';
      } else if (button.innerHTML == 'View less') {
        for (var i = 20; i < $('.equipment-filters-compressed').children().length; i++){
          if (!$('.equipment-filters-compressed').children()[i].classList.contains('d-none')){
            $('.equipment-filters-compressed').children()[i].classList.add('d-none');
          }
        }
        button.innerHTML = 'View all';
      }
    }

    expandServiceFiltersCompressed = (e) => {
      var button = $('.service-filters-compressed').next().get(0);

      if (button.innerHTML == 'View all'){
        for (var i = 0; i < $('.service-filters-compressed').children().length; i++){
          if ($('.service-filters-compressed').children()[i].classList.contains('d-none')){
            $('.service-filters-compressed').children()[i].classList.remove('d-none');
          }
        }
        button.innerHTML = 'View less';
      } else if (button.innerHTML == 'View less') {
        for (var i = 20; i < $('.service-filters-compressed').children().length; i++){
          if (!$('.service-filters-compressed').children()[i].classList.contains('d-none')){
            $('.service-filters-compressed').children()[i].classList.add('d-none');
          }
        }
        button.innerHTML = 'View all';
      }
    }

    expandFilterMenu = () => {
      if ($('#expanded').css('display') == 'none'){
        $('#expanded').show()
      } else {
        $('#expanded').hide()
      }
    }

    windowResize({ width }) {
      // Ensure that filter becomes expanded when changing window size
      if (width > 480) {
        $('#expanded').show()
      }
    }

}