import { Controller } from "@hotwired/stimulus"

const EQUIPMENT_MARKER_COLOR = "#2998C9";
const SERVICE_MARKER_COLOR = "#29B984";
const DEFAULT_LATITUDE = 41.850033;
const DEFAULT_LONGITUDE = -87.6500523;

// Connects to data-controller="map"
export default class extends Controller {
    static targets = ['form', 'map'];

    initialize() {

    }

    connect() {
        var startingLatitude = DEFAULT_LATITUDE;
        var startingLongitude = DEFAULT_LONGITUDE;
        if (this.data.get('latitude') != "")
            startingLatitude = this.data.get('latitude');
        if (this.data.get('longitude') != "")
            startingLongitude = this.data.get('longitude');
        window.map = this.createMap(this.data.get('address'), startingLatitude, startingLongitude);
        this.addMarkers(window.map);
    }
    
    disconnect() {
        delete window.map;
    }

    isGeocoded(user) {
        return (user.address && user.latitude && user.longitude);
    };

    createMap(address, startingLatitude, startingLongitude) {
        mapboxgl.accessToken = process.env.MAP_BOX_API_KEY;
        var zoomIn = 3;
        // Zoom in further if the latitude and longitude are specified, else show entire U.S.
        if (startingLongitude != DEFAULT_LONGITUDE && startingLongitude != DEFAULT_LONGITUDE){
            zoomIn = 8;
        }
        const map = new mapboxgl.Map({
            container: 'map', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [startingLongitude, startingLatitude], // starting position [lng, lat]
            zoom: zoomIn // starting zoom
        });

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            countries: 'us'
        })

        // Only add the geocoder search bar if it does not already exist (browser back button otherwise causes duplicates)
        if (!$('.mapboxgl-ctrl-geocoder--input').length) {
            document.getElementById('location-placeholder').appendChild(geocoder.onAdd(map));
        }

        if (address != "" && address != 'Anywhere')
            $('.mapboxgl-ctrl-geocoder--input').attr("placeholder", address).val(address)

        return map
    }

    addMarkers(map){
        const element = document.querySelector('.search')
        if (!element) {
            return;
        }
    
        const listings = (window.listings = JSON.parse(element.dataset.listings));
        const users = JSON.parse(element.dataset.users)

        listings.forEach((listing) => {
            var user = users.find(user => user.id == listing.user_id)
            if (user == null)
                return

            const popup = new mapboxgl.Popup({ offset: 25 })
                            .setHTML(`<h5><strong><a href="/listings/${listing.id}">${listing.category}</a></strong></h5>
                                    <p>Listed by <i><a href="/profile/${user.id}">${user.name}</a></i><p>
                                    <p>Description: ${listing.description}</p>`)

            let marker_color;
            if (listing.hasOwnProperty('model')) // this means the listing is an equipment
                marker_color = EQUIPMENT_MARKER_COLOR;
            else
                marker_color = SERVICE_MARKER_COLOR;


            if (this.isGeocoded(user)) {
                const latitude = user.latitude
                    ? user.latitude
                    : DEFAULT_LATITUDE
                const longitude = user.longitude
                    ? user.longitude
                    : DEFAULT_LONGITUDE
                new mapboxgl.Marker({color: marker_color})
                    .setLngLat([longitude + Math.random() * 0.0005, latitude + Math.random() * 0.0005])
                    .setPopup(popup)
                    .addTo(map);
            }
        });
    }
}
